<template>
	<div class="fill-height  pa-5 pa-sm-10" v-if="AppStore.started_app" id="home-page"
         :style='"background: url("+ AppStore.data.configuration[AppStore.locale].img +") no-repeat;background-size: cover;"'>
		<v-container fluid class="fill-height d-flex align-center flex-row">
			<v-row class="text-center" align="center" justify="center">
				<v-card elevation="0" class="mx-auto pa-sm-10" rounded="xl" height="100%">
					<v-col cols="12" class="text-h1 my-auto" id="home-title"
					       v-html="AppStore.data.configuration[AppStore.locale].title_1">
					</v-col>
					<v-col cols="12" class="text-center" id="home-stars">
						<v-rating v-model="AppStore.rating.rate" @input="EventBus.$emit('launchHomeWatcher', {})">
							<template v-slot:item="props">
								<v-icon
									:style="$vuetify.breakpoint.smAndUp ? 'font-size: 125px;' : 'font-size: 55px;'"
									:color="getRateColor(props.isFilled)"
									small
									class="pa-0 rate-icon"
                                    @click="props.click">
									{{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
								</v-icon>
							</template>
						</v-rating>
					</v-col>

					<v-col cols="12" style="min-height:64px">
						<!--						<v-slide-x-transition>-->
						<div class="text-subtitle-1 text-center" id="subtitle" :style="'color:'+Helpers.getColor(AppStore.data.general.secondary_color.hexa)"
						     v-show="!_.includes([1,2,3,4,5], AppStore.rating.rate)"
						     v-html="AppStore.data.configuration[AppStore.locale].subtitle"></div>
						<!--						</v-slide-x-transition>-->
						<!--						<v-slide-x-transition mode="out-in">-->
						<div v-show="_.includes([1,2,3,4,5], AppStore.rating.rate)">
							<v-btn @click="AppStore.rating.rate = 0" @keypress.enter="navigate" role="link" rounded
							       outlined elevation="0"
                                   :large="AppStore.data.general.button_size === 'large'"
                                   :x-large="AppStore.data.general.button_size === 'x-large'"
                                   :x-small="AppStore.data.general.button_size === 'x-small'"
                                   :small="AppStore.data.general.button_size === 'small'"
							       :color="Helpers.getColor(AppStore.data.general.primary_color.hexa)" class="text-button mr-0 mr-sm-12 revert-btn"
                                   :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
							       v-html="AppStore.data.configuration[AppStore.locale].rate_retry_btn_text">
							</v-btn>

							<v-btn @click="submit" role="link" @keypress.enter="navigate" rounded elevation="0" dark
                                   :large="AppStore.data.general.button_size === 'large'"
                                   :x-large="AppStore.data.general.button_size === 'x-large'"
                                   :x-small="AppStore.data.general.button_size === 'x-small'"
                                   :small="AppStore.data.general.button_size === 'small'"
							       :color="Helpers.getColor(AppStore.data.general.primary_color.hexa)"
							       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
							       class="text-button ml-0 ml-sm-12 mt-3 mt-sm-0"
							       v-html="AppStore.data.configuration[AppStore.locale].rate_validate_btn_text">
							</v-btn>
						</div>
						<!--						</v-slide-x-transition>-->
					</v-col>
				</v-card>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import RatingsCarousel from "../../../components/tb/1/RatingsCarousel.vue";
import AppStore from "../../../stores/AppStore";
import LocaleChanger from "../../../components/LocaleChanger.vue";
import _ from 'lodash';
import {EventBus} from "../../../services/Request";
import Helpers from "../../../services/Helpers";
export default {
	name: 'home-view',

	data: () => ({
		AppStore,
        EventBus,
		Helpers,
    }),

	components: {
		LocaleChanger,
		RatingsCarousel
	},

	props: {},

	created() {
		if (!AppStore.started_app) {
			const url = this.$route.path.toString().split("/")
			EventBus.$emit('getApp', {type: url[1], model: url[2], slug: this.$route.params.slug, params: this.$route.query, noredirect: false})
		}
	},

	mounted() {

	},

	computed: {
		_() {
			return _;
		},
	},

	watch: {},

	methods: {
		submit() {
            if(AppStore.data.configuration.skip_appreciation_page){
                this.$router.push({name: 'tb-1-comment'})
            } else {
                this.$router.push({name: 'tb-1-appreciation'})
            }
		},

        getRateColor(isFilled) {
            let color = 'orange'
            if(isFilled){
                if(!AppStore.data.configuration.override_rate_stars_color && AppStore.data.configuration.default_rate_stars_color.hexa){
                    color = Helpers.getColor(AppStore.data.configuration.default_rate_stars_color.hexa)
                } else {
                    color = Helpers.getColor(AppStore.data.configuration['override_rate_stars_color_star_'+AppStore.rating.rate].hexa)
                }
            } else {
	            color = 'grey lighten-0'
            }

            return color
        }
	}
}
</script>
<style>

</style>